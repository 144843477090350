.ais-SearchBox-input {
  margin: 0px !important;
  border-radius: 18px;
  font-family: 'Open Sans', sans-serif;
  margin: 0 8px;
  background-color: #ecedf1;
  border: none;
  height: 37px;
  padding-left: 2.3rem;
}

.ais-SearchBox-submit {
  left: 0.9rem;
}

.ais-Hits-item {
  width: 100%;
  margin: 8px;
}

.ais-Hits-item:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.hit:focus {
  background-color: #eeeeee;
}
.ais-Hits-item {
  border: none;
  border-bottom: 1px solid #adadad;
  box-shadow: none;
  padding: 0.3rem 0.6rem;
}
.ais-Highlight-highlighted {
  font-weight: 600;
  font-style: normal !important;
  background-color: transparent;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  border-radius: 16px;
}
.ais-Pagination-link {
  border-radius: 16px;
  border-bottom: 1px solid #adadad;
}
.ais-Hits-list {
  margin: 0;
}

.ais-SearchBox-input {
  font-family: 'InnoArial' !important;
  color: rgb(102, 120, 138);
}
.ais-SearchBox-input:focus {
  outline: none;
}
