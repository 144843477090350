@font-face {
  font-family: 'InnoArial';
  src: url('./static/fonts/innotype.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

/* Remove horizontal bars */
/* Hide scrollbar for Chrome, Safari and Opera */
#featuredContainer::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE and Edge */
#featuredContainer {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
