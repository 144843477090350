@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
/* Refactor Flag: Merge Index.csss and App.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiFilledInput-underline::after,
.MuiFilledInput-underline::before {
  border-bottom: 'none';
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.MuiInputLabel-root {
  margin-bottom: 8px;
}

.MuiSelect-select:focus {
  background-color: inherit !important ;
}
/* CK Editor Styles */
.ck-toolbar {
  border-radius: 10px 10px 0px 0px !important;
  border-color: #ecedf1 !important;
  background-color: #ecedf1 !important;
}
.ck {
  font-family: 'InnoArial' !important;
  color: #727272;
}
.ck-content {
  border-color: #ecedf1 !important;
  border-width: 2px !important;
  border-radius: 0px 0px 10px 10px !important;
  font-family: 'Open Sans' !important;
  min-height: 120px !important;
}
.ck-focused {
  box-shadow: none !important;
}

@font-face {
  font-family: 'InnoArial';
  src: url(/static/media/innotype.c72cb10c.ttf) format('truetype');
}

/* Remove horizontal bars */
/* Hide scrollbar for Chrome, Safari and Opera */
#featuredContainer::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE and Edge */
#featuredContainer {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.ais-SearchBox-input {
  border-radius: 18px;
  font-family: 'Open Sans', sans-serif;
  margin: 0 8px;
  background-color: #ecedf1;
  border: none;
  height: 37px;
  padding-left: 2rem;
}

.ais-SearchBox-submit {
  left: 0.9rem;
}

.ais-Hits-item {
  width: 100%;
  margin: 8px;
}

.ais-Hits-item:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.hit:focus {
  background-color: #eeeeee;
}
.ais-Hits-item {
  border: none;
  border-bottom: 1px solid #adadad;
  box-shadow: none;
  padding: 0.3rem 0.6rem;
}
.ais-Highlight-highlighted {
  font-weight: 600;
  font-style: normal !important;
  background-color: transparent;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  border-radius: 16px;
}
.ais-Pagination-link {
  border-radius: 16px;
  border-bottom: 1px solid #adadad;
}
.ais-Hits-list {
  margin: 0;
}

.ais-SearchBox-input {
  margin: 0px !important;
  border-radius: 18px;
  font-family: 'Open Sans', sans-serif;
  margin: 0 8px;
  background-color: #ecedf1;
  border: none;
  height: 37px;
  padding-left: 2.3rem;
}

.ais-SearchBox-submit {
  left: 0.9rem;
}

.ais-Hits-item {
  width: 100%;
  margin: 8px;
}

.ais-Hits-item:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.hit:focus {
  background-color: #eeeeee;
}
.ais-Hits-item {
  border: none;
  border-bottom: 1px solid #adadad;
  box-shadow: none;
  padding: 0.3rem 0.6rem;
}
.ais-Highlight-highlighted {
  font-weight: 600;
  font-style: normal !important;
  background-color: transparent;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  border-radius: 16px;
}
.ais-Pagination-link {
  border-radius: 16px;
  border-bottom: 1px solid #adadad;
}
.ais-Hits-list {
  margin: 0;
}

.ais-SearchBox-input {
  font-family: 'InnoArial' !important;
  color: rgb(102, 120, 138);
}
.ais-SearchBox-input:focus {
  outline: none;
}

