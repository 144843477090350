/* Refactor Flag: Merge Index.csss and App.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiFilledInput-underline::after,
.MuiFilledInput-underline::before {
  border-bottom: 'none';
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.MuiInputLabel-root {
  margin-bottom: 8px;
}

.MuiSelect-select:focus {
  background-color: inherit !important ;
}
/* CK Editor Styles */
.ck-toolbar {
  border-radius: 10px 10px 0px 0px !important;
  border-color: #ecedf1 !important;
  background-color: #ecedf1 !important;
}
.ck {
  font-family: 'InnoArial' !important;
  color: #727272;
}
.ck-content {
  border-color: #ecedf1 !important;
  border-width: 2px !important;
  border-radius: 0px 0px 10px 10px !important;
  font-family: 'Open Sans' !important;
  min-height: 120px !important;
}
.ck-focused {
  box-shadow: none !important;
}
